import { BehaviorSubject, Observable, of, Subscriber, Subscription } from "rxjs";
import { base64StringToBlob } from "blob-util";
import { FileBase64, FileBlob, FileUploaded } from "../models/file.model";
import { Injector } from "@angular/core";
import { HttpClient, HttpXhrBackend } from "@angular/common/http";
import { BaseApiResponse } from "../../bases/models/base-api.model";
import { environment } from "src/environments/environment";
import { UserModel } from "../models/user.model";
import { GroupingState, ITableState, PaginatorState, SortState, TableResponseModel } from "../crud-table";
import { catchError, switchMap } from "rxjs/operators";

export const defaultGUID = "00000000-0000-0000-0000-000000000000";

export class shareProperties {
    public static injectorInstance: Injector;
    public static currentUserSubject: BehaviorSubject<UserModel> = new BehaviorSubject<UserModel>(null);
    public static currentUser$: Observable<UserModel> = shareProperties.currentUserSubject.asObservable();
}

export const readFileToBase64 = (file: File): Observable<FileBase64> => {
    const fileReader = new FileReader();

    // init read
    fileReader.readAsDataURL(file);

    return Observable.create((observer: Subscriber<FileBase64>): void => {
        // if success
        fileReader.onload = (ev: ProgressEvent): void => {
            observer.next({
                file: file,
                base64: fileReader.result as string
            });
            observer.complete();
        };

        // if failed
        fileReader.onerror = (error: any): void => {
            observer.error(error);
        };
    });
}

export const readFileToBlob = (file: File): Observable<FileBlob> => {
    const fileReader = new FileReader();
    const type = file.type;

    // init read
    fileReader.readAsDataURL(file);

    return Observable.create((observer: Subscriber<FileBlob>): void => {
        // if success
        fileReader.onload = (ev: ProgressEvent): void => {
            const base64 = fileReader.result as string;
            const blob = base64StringToBlob(base64.split(",").pop(), type);
            observer.next({
                file: file,
                blob: blob
            });
            observer.complete();
        };

        // if failed
        fileReader.onerror = (error: any): void => {
            observer.error(error);
        };
    });
}

export const readFileToBase64Async = async (file: File): Promise<FileBase64> => {
    return new Promise((resolve, reject) => {
        readFileToBase64(file).subscribe(result => {
            resolve(result);
        }, error => {
            reject(error);
        });
    });
}

export const readFileToBlobAsync = async (file: File): Promise<FileBlob> => {
    return new Promise((resolve, reject) => {
        readFileToBlob(file).subscribe(result => {
            resolve(result);
        }, error => {
            reject(error);
        });
    });
}

export const generateWebSocketUrl = (url: string, params: any): string => {
    let sUrl = url;
    Object.keys(params).forEach(key => {
        sUrl = sUrl.replace(`{${key}}`, params[key]);
    });
    return sUrl;
}

export const manupulateDatetimeFromAPI = (dateTime: any): Date => {
    if (dateTime) {
        const result = new Date(dateTime);
        result.setTime(result.getTime() - (result.getTimezoneOffset() * 60 * 1000));
        return result;
    } else {
        return null;
    }
}

export const convertDatetimeToAPI = (dateTime: any): Date => {
    if (dateTime) {
        const result = new Date(dateTime);
        result.setTime(result.getTime() - (result.getTimezoneOffset() * 60 * 1000));
        return result;
    } else {
        return null;
    }
}

export const seperateProductCode = (prodCode: string): any => {
    if (!prodCode)
        return {
            corrugatedType: null,
            paperGradeSet: null,
            noOfLayer: 0,
            outsideLayer: null,
            insideLayer: null,
            middleLayer1: null,
            middleLayer2: null,
            middleLayer3: null,
        };
    const split = prodCode.split("(");
    const paperGradeSet = split[1].split(")")[0];
    const paperGradeList = paperGradeSet.split(",");
    return {
        corrugatedType: split[0],
        paperGradeSet: paperGradeSet,
        noOfLayer: paperGradeList.length,
        outsideLayer: paperGradeList[0],
        insideLayer: paperGradeList[paperGradeList.length - 1],
        middleLayer1: paperGradeList.length >= 3 ? paperGradeList[1] : null,
        middleLayer2: paperGradeList.length >= 4 ? paperGradeList[2] : null,
        middleLayer3: paperGradeList.length >= 5 ? paperGradeList[3] : null,
    };
}

export const getProductDetail = (http: HttpClient, customerCode: string, productCode: string, asOfDate: Date, quantity: number, getStdPrice: boolean, getStdDiscount: boolean, getSpDiscount: boolean) => {
    const urlGetProductList = `${environment.apiUrl}/masterdata/product/paging`;
    const params: ITableState = {
        filter: {
            customerCode: customerCode,
            productCode: productCode,
            getStandardPrice: getStdPrice,
            getStandardDiscount: getStdDiscount,
            getSpecialDiscount: getSpDiscount,
            asOfDate: asOfDate,
            quantity: quantity
        },
        grouping: new GroupingState(),
        paginator: new PaginatorState(),
        sorting: new SortState(),
        searchTerm: "",
        language: "en",
        entityId: 0
    };

    params.paginator.page = 1;
    params.paginator.pageSize = 1;

    return http.post<BaseApiResponse<TableResponseModel<any>>>(urlGetProductList, params).pipe(
        switchMap(resp => {
            if (resp.status == 200 && resp.result?.items?.length > 0) {
                return of(resp.result.items[0]);
            } else {
                return of(null);
            }
        }),
        catchError(err => {
            return of(null);
        })
    );
}