export const environment = {
  environmentName: "DEV",
  defaultLanguage: "TH",
  systemId: "thb-cms",
  production: true,
  appVersion: 'v726demo3',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: false,
  apiUrl: '/api',
  tokenKey: 'auth-token-dev',
  languageKey: 'auth-language-dev',
  userKey: 'auth-user-dev',
  cmsUrl: 'https://thb-dev.better-and-co.com',
  editorKey : 'rxn19ds8274wsojgtupjqvzm4qh90l4d9lsjfqq5i77h7qxk'
  // apiUrl: 'https://your-domain.com/api'
  // apiUrl: 'mysite.com/api'
};
