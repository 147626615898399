import { HTTP_INTERCEPTORS, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TokenStorageService } from '../modules/auth/services/token-storage.service';

const TOKEN_HEADER_KEY = 'Authorization';       // for Spring Boot back-end
const LANGUAGE_HEADER_KEY = 'Language';       // for Spring Boot back-end
const SYSTEMID_HEADER_KEY = 'System-ID';       // for Spring Boot back-end

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private token: TokenStorageService,
    private router: Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;
    const token = this.token.getToken();
    if (token != null) {
      let headers = req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token);
      headers = headers.set(LANGUAGE_HEADER_KEY, this.token.getLanguage());
      headers = headers.set(SYSTEMID_HEADER_KEY, environment.systemId);
      authReq = req.clone({
        headers: headers
      });
    }
    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
          console.log('this is client side error');
          errorMsg = `Error: ${error.error.message}`;
        }
        else {
          console.log('this is server side error');
          errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
        }
        if (error.status === 401) {
          this.router.navigate(['auth', 'login'], {
            queryParams: { returnUrl: this.router.url }
          });
        }

        console.log(errorMsg);
        return throwError(errorMsg);
      })
    );
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];