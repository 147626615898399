import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const TOKEN_KEY = environment.tokenKey;
const LANGUAGE_KEY = environment.languageKey;
const USER_KEY = environment.userKey;

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  constructor() { }

  signOut(): void {
    window.localStorage.clear();
  }

  public saveToken(token: string): void {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
    return window.localStorage.getItem(TOKEN_KEY);
  }

  public clearToken(): void {
    return window.localStorage.removeItem(TOKEN_KEY);
  }

  public saveLanguage(lang: string): void {
    window.localStorage.removeItem(LANGUAGE_KEY);
    window.localStorage.setItem(LANGUAGE_KEY, lang);
  }

  public getLanguage(): string {
    return window.localStorage.getItem(LANGUAGE_KEY) || environment.defaultLanguage;
  }

  public clearLanguage(): void {
    window.localStorage.removeItem(LANGUAGE_KEY);
  }

  public saveUser(user: any): void {
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    const user = window.localStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }

    return {};
  }

  public clearUser(): void {
    window.localStorage.removeItem(USER_KEY);
  }
}